<template>
	<div ref="ProjectMigration" class="dialog-container">
		<div class="dialog" style="max-width: 1300px; max-height: 750px; transform: translate(0, -420px);">
			<div class="top"> 
				<p class="txt-crud-title">
					Project Data Migration
				</p>
			</div>
			<div>
				
			</div>
			<p class="txt-list-total" style="margin: 0;">
				There are <span >{{ screeningList.length }}</span> subjects in total.
			</p>
			<div class="center field-flex" style="height: calc(100% - 131px);">
				<div class="scroll" style="width: 100%; height: 100%; overflow-y: auto;">
					<table class="tb-list-vertical no-cursor">
						<colgroup>
							<col width="20px" />
							<col width="3%" />
							<col width="20%" />
							<col width="auto" />
							<!-- <col width="15%" />
							<col width="15%" /> -->
						</colgroup>
						<thead style="position: sticky; top: 0; z-index: 1;">
							<tr>
                                <th colspan="3">Target Project</th>
                                <td colspan="4" style="border-top: 1px solid #ccc;">
									<select class="select-crud" v-model="targetProjectNo">
										<!-- <option v-if="targetProjectNo == ''" value="">
											select target project
										</option> -->
										<template v-for="(project, index) in projectList">
											<option 
												:key="index + 'project'"
												:value="project.projectNo"
											>
												{{'V' + project.projectVersion + ' : [' + project.protocolName + ']' + project.projectStudyTitle}}
											</option>
										</template>
									</select>
                                </td>
                            </tr>
							<tr>
								<th>
									<label class="check-label">
										<input
											type="checkbox"
											name="checkAll"
											@click="checkAll($event)"
										/>
										<span class="checkbox"></span>
									</label>
								</th>
								<th>
									No
								</th>
								<th>
									Site
								</th>
								<th>
									Subject
								</th>
								<th>
									Subject No
								</th>
								<th>
									Consent Date
								</th>
								<th>
									Regist Date
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in screeningList"
								:key="index"
								tabindex="0"
							>
								<td>
									<label class="check-label">
										<input
											type="checkbox"
											name="screeningNo"
											:checked="(checkedList.indexOf(item.screeningNo) >= 0 ? true : false)"
											@click="check(item.screeningNo)"	
										/>
										<span class="checkbox"></span>
									</label>
								</td>
								<td>
									{{ item.rowNumber }}
								</td>
								<td>
									{{ item.siteCodeName }}
								</td>
								<td>
									<span class="underline" style="cursor: auto;">
										{{ item.screeningName }}
									</span>
								</td>
								<td>
									{{ item.subjectNo != '' ? item.subjectNo : '-'}}
								</td>
								<td>
									{{ item.informedConsentDt }}
								</td>
								<td>
									{{ item.firstRegistDate.substring(0, 10) }}
								</td>
							</tr>
							<tr v-if="screeningList.length == 0">
								<td colspan="6" class="no-list">
									There is no registered data, Please register the data.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="survey-category scroll" style="margin: 0; height: 100%; overflow-y: scroll; overflow-x: hidden; padding-right: 20px; display:none;">
					<div class="dic-category-list scroll no-drag" style="margin: 0; border-top: 1px solid #ccc; height: 100%; overflow: initial;">
						<div
							class="field-group"
						>
							<button
								type="button"
								class="group-name group-type"
								style="cursor: auto !important;"
							>
								<div class="allIcon">
									A
								</div>
								<div class="dic-list">
									All Select
								</div>
								<label class="check-label label-right">
									<input
										type="checkbox"
										name="checkAll"
										v-model="isAllCheck"
										@click.stop="allCheck('ALL', $event)"
									/>
									<span class="checkbox"></span>
								</label>
							</button>
						</div>
						<div
							v-for="(visitItem, vindex) in fieldList"
							:key="vindex"
							class="field-group"
							:class="(visitOpenList.indexOf(visitItem.centralEdcVisitNo) >= 0 ? '' : 'group-off')"
						>
							<button
								type="button"
								class="group-name group-type"
								:title="visitItem.visitName"
								style="cursor: auto !important;"
							>
								<div class="groupIcon">
									V
								</div>
								<div class="dic-list">
									{{ visitItem.visitName + " "}}
									<span style="color: #bbb;">{{ (visitItem.unscheduleYn == 'Y' ? '[UnSchedule]': '') }}</span>
								</div>
								<label class="check-label label-right">
									<input
										type="checkbox"
										v-model="checkedVisitList"
										:value="visitItem.centralEdcVisitNo"
										@click.stop="allCheck('VISIT', $event, visitItem)"
									/>
									<span class="checkbox"></span>
								</label>
								<div
									class="groupDropdown"
									style="cursor: pointer;"
									@click.stop="foldVisit(visitItem.centralEdcVisitNo)"
								>
									&gt;
								</div>
							</button>
							<div
								v-for="(pageItem, pIndex) in visitItem.pageList"
								:key="pIndex"
								class="field-group field-question"
								:class="(pageOpenList.indexOf(visitItem.centralEdcVisitNo+'/'+pageItem.centralEdcPageNo) >= 0 ? '' : 'group-off')"
							>
								<button
									type="button"
									class="group-name field-type"
									:title="pageItem.pageLabel"
									style="cursor: auto !important;"
								>
									<div class="fieldIcon">
										Pa
									</div>
									<div class="dic-list">
										{{ pageItem.pageLabel }}
									</div>
									<label class="check-label label-right">
										<input
											type="checkbox"
											v-model="checkedPageList"
											:value="visitItem.centralEdcVisitNo+'/'+pageItem.centralEdcPageNo"
											@click.stop="allCheck('PAGE', $event, visitItem, pageItem)"
										/>
										<span class="checkbox"></span>
									</label>
									<div
										class="fieldDropdown"
										style="cursor: pointer;"
										@click.stop="foldPage(visitItem.centralEdcVisitNo, pageItem.centralEdcPageNo)"
									>
										&gt;
									</div>
								</button>
								<div
									v-for="(procItem, prIndex) in pageItem.procedureList"
									:key="prIndex"
									class="field-group field-question"
									:class="(procOpenList.indexOf(visitItem.centralEdcVisitNo+'/'+pageItem.centralEdcPageNo+'/'+procItem.procNo) >= 0 ? '' : 'group-off')"
								>
									<button
										type="button"
										class="group-name proc-type"
										:title="procItem.pageLabel"
										style="cursor: auto !important;"
									>
										<div class="procIcon">
											Pr
										</div>
										<div class="dic-list">
											{{ procItem.procLabel }}
										</div>
										<label class="check-label label-right">
											<input
												type="checkbox"
												v-model="checkedProcList"
												:value="visitItem.centralEdcVisitNo+'/'+pageItem.centralEdcPageNo+'/'+procItem.procNo"
												@click.stop="allCheck('PROC', $event, visitItem, pageItem, procItem)"
											/>
											<span class="checkbox"></span>
										</label>
										<div
											class="fieldDropdown"
											style="cursor: pointer;"
											@click.stop="foldProc(visitItem.centralEdcVisitNo, pageItem.centralEdcPageNo, procItem.procNo)"
										>
											&gt;
										</div>
									</button>
									<div
										v-for="(procQuestionItem, pqIndex) in procItem.procQuestionList"
										:key="pqIndex"
										class="field-group field-question"
									>
										<button
											type="button"
											class="group-name procQuestion-type"
											:title="procQuestionItem.questionName"
											style="cursor: auto !important;"
										>
											<div class="procQuestionIcon">
												F
											</div>
											<label class="check-label label-right">
												<input
													type="checkbox"
													v-model="checkedProcQuestionList"
													:value="visitItem.centralEdcVisitNo+'/'+pageItem.centralEdcPageNo+'/'+procItem.procNo+'/'+procQuestionItem.fieldList[0].centralEdcProcFieldNo"
													@click.stop="allCheck('PROCQUESTION', $event, visitItem, pageItem, procItem, procQuestionItem)"
												/>
												<span class="checkbox"></span>
											</label>
											<div class="dic-list">
												{{ procQuestionItem.questionName + " [" + procQuestionItem.questionFieldName + "]" }}
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<button type="button" class="btn-save fright" title="Create" @click.stop="migrationData()">
					Migration
				</button>
				<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
					Cancel
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProjectMigration",
	created() {
		this.logger.debug(this, "created()");
		this.item.projectNo = this.projectNo;
		this.selectProjectList();
		this.selectScreeningList();
		this.selectProcFieldList();
	},
	props: {
		projectNo: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			item: {},
			projectList: [],
			screeningList: [],
			checkedList: [],
			fieldList: [],
			visitOpenList : [],
			pageOpenList: [],
			procOpenList: [],
			isClicked: {},
			checkedVisitList: [],
			checkedPageList: [],
			checkedProcList: [],
			checkedProcQuestionList: [],
			isAllCheck: false,
			targetProjectNo: "",
		};
	},
	methods: {
		selectProjectList() {
			this.logger.debug(this, "selectProject()");

			axios
				.post(this.config.contextRoot + "/project/selectMigrationList.do", this.item)
				.then((response) => {
					console.log(response);
					this.projectList = response.data.projectList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		selectScreeningList() {
			this.logger.debug(this, "selectScreeningList()");
			axios
				.post(this.config.contextRoot + "/screening/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.screeningList = response.data.screeningList;
					this.pagination = response.data.pagination;
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProcFieldList(){
			EventBus.$emit("project-loading", true);
			this.logger.debug(this, "selectProcFieldList()");
			this.isClicked = {};
			axios
				.post(this.config.contextRoot + "/project/allProcList.do", this.item)
				.then((response) => {
					console.log(response);
					this.fieldList = response.data.fieldList;
					this.$nextTick(function () {
						EventBus.$emit("project-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Are you sure you want to cancel the data export of the project?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ProjectMigration.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.ProjectMigration.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isMigration");
			}.bind(this), 410);
		},
		check(screeningNo) {
			this.logger.debug(this, "check(screeningNo)");
			let index = this.checkedList.indexOf(screeningNo);
			if(index >= 0)
			{
				this.checkedList.splice(index, 1);
			}
			else
			{
				this.checkedList.push(screeningNo);
			}
		},
		checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			if(event.target.checked)
			{
				this.checkedList = this.screeningList.map((item)=>{
					return item.screeningNo;
				});
			}
			else
			{
				this.checkedList = [];
			}
		},
		migrationData() {
			this.logger.debug(this, "migrationData()");

			for(let i = 0; i < this.fieldList.length; i++){
				if(this.checkedVisitList.indexOf(this.fieldList[i].centralEdcVisitNo) < 0){
					this.checkedVisitList.push(this.fieldList[i].centralEdcVisitNo);
				}
				for(let j = 0; j < this.fieldList[i].pageList.length; j++){	
					if(this.checkedPageList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo) < 0){
						this.checkedPageList.push(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo);
					}
					for(let k = 0; k < this.fieldList[i].pageList[j].procedureList.length; k++){
						if(this.checkedProcList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo) < 0){
							this.checkedProcList.push(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo);
						}
						for(let l = 0; l < this.fieldList[i].pageList[j].procedureList[k].procQuestionList.length; l++){
							if(this.checkedProcQuestionList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].fieldList[0].centralEdcProcFieldNo) < 0){
								this.checkedProcQuestionList.push(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].fieldList[0].centralEdcProcFieldNo);
							}	
						}
					}
				}
			}

			if(this.checkedPageList.length > 0){
				for(var i = 0; i < this.checkedPageList.length; i++){
					var splitdata = this.checkedPageList[i].split("/");
					if(splitdata[1] == 0){
						this.$alert("Only site data can be migrated.", "Warn", "warning");
						return false;
					}
				}
			}

			if(this.targetProjectNo == ''){
				this.$alert("There is no target project selected.", "Warn", "warning");
				return false;
			}
			else if(this.checkedList.length <= 0){
				this.$alert("There is no subject selected.", "Warn", "warning");
				return false;
			}
			else if(this.checkedProcQuestionList.length <= 0){
				this.$alert("There is no data selected.", "Warn", "warning");
				return false;
			}
			else{
				EventBus.$emit("project-loading", true);
	
				let items = {
					projectNo : this.item.projectNo,
					screeningNoList : this.checkedList,
					procQuestionList :  this.checkedProcQuestionList,
					targetProjectNo : this.targetProjectNo
				}
	
				axios
					.post(this.config.contextRoot + "/project/transfer/migrationData.do", items)
					.then((response) => {
						console.log(response);
						this.$alert("Screening entry data has been successfully migrationed.","Success","success").then(() => {
							location.reload();
						});
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			}
		},
		foldVisit(centralEdcVisitNo) {
			this.logger.debug(this, "foldVisit(centralEdcVisitNo)");
			let index = this.visitOpenList.indexOf(centralEdcVisitNo);
			if(index >= 0)
			{
				this.visitOpenList.splice(index, 1);
			}
			else
			{
				this.visitOpenList.push(centralEdcVisitNo);
			}
		},
		foldPage(centralEdcVisitNo, centralEdcPageNo) {
			this.logger.debug(this, "foldPage(centralEdcPageNo)");
			let index = this.pageOpenList.indexOf(centralEdcVisitNo+'/'+centralEdcPageNo);
			if(index >= 0)
			{
				this.pageOpenList.splice(index, 1);
			}
			else
			{
				this.pageOpenList.push(centralEdcVisitNo+'/'+centralEdcPageNo);
			}
		},
		foldProc(centralEdcVisitNo, centralEdcPageNo, procNo) {
			this.logger.debug(this, "foldProc(procNo)");
			let index = this.procOpenList.indexOf(centralEdcVisitNo+'/'+centralEdcPageNo+'/'+procNo);
			if(index >= 0)
			{
				this.procOpenList.splice(index, 1);
			}
			else
			{
				this.procOpenList.push(centralEdcVisitNo+'/'+centralEdcPageNo+'/'+procNo);
			}
		},
		allCheck(type, event, visit, page, proc){
			this.logger.debug(this, "allCheck(event)");
			if(type == 'ALL'){
				if (event.target.checked) {
					this.isAllCheck = true;

					for(let i = 0; i < this.fieldList.length; i++){
						if(this.checkedVisitList.indexOf(this.fieldList[i].centralEdcVisitNo) < 0){
							this.checkedVisitList.push(this.fieldList[i].centralEdcVisitNo);
						}
						for(let j = 0; j < this.fieldList[i].pageList.length; j++){	
							if(this.checkedPageList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo) < 0){
								this.checkedPageList.push(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo);
							}
							for(let k = 0; k < this.fieldList[i].pageList[j].procedureList.length; k++){
								if(this.checkedProcList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo) < 0){
									this.checkedProcList.push(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo);
								}
								for(let l = 0; l < this.fieldList[i].pageList[j].procedureList[k].procQuestionList.length; l++){
									if(this.checkedProcQuestionList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].fieldList[0].centralEdcProcFieldNo) < 0){
										this.checkedProcQuestionList.push(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].fieldList[0].centralEdcProcFieldNo);
									}	
								}
							}
						}
					}
				}
				else{
					this.isAllCheck = false;

					for(let i = 0; i < this.fieldList.length; i++){
						if(this.checkedVisitList.indexOf(this.fieldList[i].centralEdcVisitNo) >= 0){
							this.checkedVisitList.splice(this.checkedVisitList.indexOf(this.fieldList[i].centralEdcVisitNo), 1);
						}
						for(let j = 0; j < this.fieldList[i].pageList.length; j++){	
							if(this.checkedPageList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo) >= 0){
								this.checkedPageList.splice(this.checkedPageList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo), 1);
							}
							for(let k = 0; k < this.fieldList[i].pageList[j].procedureList.length; k++){
								if(this.checkedProcList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo) >= 0){
									this.checkedProcList.splice(this.checkedProcList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo), 1);
								}
								for(let l = 0; l < this.fieldList[i].pageList[j].procedureList[k].procQuestionList.length; l++){
									if(this.checkedProcQuestionList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].fieldList[0].centralEdcProcFieldNo) >= 0){
										this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(this.fieldList[i].centralEdcVisitNo + '/' + this.fieldList[i].pageList[j].centralEdcPageNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procNo + '/' + this.fieldList[i].pageList[j].procedureList[k].procQuestionList[l].fieldList[0].centralEdcProcFieldNo), 1);
									}	
								}
							}
						}
					}
				}
			}
			if(type == 'VISIT'){
				if(this.checkedVisitList.indexOf(visit.centralEdcVisitNo) < 0){
					this.checkedVisitList.push(visit.centralEdcVisitNo);
				}

				if (event.target.checked) {
					for(let i = 0; i < visit.pageList.length; i++){
						if(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo) < 0){
							this.checkedPageList.push(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo);
						}
						for(let j = 0; j < visit.pageList[i].procedureList.length; j++){
							if(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo) < 0){
								this.checkedProcList.push(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo);
							}
							for(let k = 0; k < visit.pageList[i].procedureList[j].procQuestionList.length; k++){
								if(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].fieldList[0].centralEdcProcFieldNo) < 0){
									this.checkedProcQuestionList.push(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].fieldList[0].centralEdcProcFieldNo);
								}
							}
						}
					}
				} 
				else {
					this.unCheckedLoop(type, visit, page, proc);

					if(this.checkedVisitList.indexOf(visit.centralEdcVisitNo) >= 0){
						this.checkedVisitList.splice(this.checkedVisitList.indexOf(visit.centralEdcVisitNo), 1);
					}

					for(let i = 0; i < visit.pageList.length; i++){
						if(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo) >= 0){
							this.checkedPageList.splice(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo), 1);
						}
						for(let j = 0; j < visit.pageList[i].procedureList.length; j++){
							if(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo) >= 0){
								this.checkedProcList.splice(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo), 1);
							}
							for(let k = 0; k < visit.pageList[i].procedureList[j].procQuestionList.length; k++){
								if(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].fieldList[0].centralEdcProcFieldNo) >= 0){
									this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + visit.pageList[i].centralEdcPageNo + '/' + visit.pageList[i].procedureList[j].procNo + '/' + visit.pageList[i].procedureList[j].procQuestionList[k].fieldList[0].centralEdcProcFieldNo), 1);
								}
							}
						}
					}
				}
			}

			if(type == 'PAGE'){
				if (event.target.checked) {
					if(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo) < 0){
						this.checkedPageList.push(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo);
					}

					for(let i = 0; i < page.procedureList.length; i++){
						if(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo) < 0){
							this.checkedProcList.push(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo);
						}
						for(let j = 0; j < page.procedureList[i].procQuestionList.length; j++){
							if(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].fieldList[0].centralEdcProcFieldNo) < 0){
								this.checkedProcQuestionList.push(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].fieldList[0].centralEdcProcFieldNo);
							}
						}
					}
				} 
				else {
					this.unCheckedLoop(type, visit, page, proc);

					if(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo) >= 0){
						this.checkedPageList.splice(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo), 1);
					}

					for(let i = 0; i < page.procedureList.length; i++){
						if(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo) >= 0){
							this.checkedProcList.splice(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo), 1);
						}
						for(let j = 0; j < page.procedureList[i].procQuestionList.length; j++){
							if(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].fieldList[0].centralEdcProcFieldNo) >= 0){
								this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + page.procedureList[i].procNo + '/' + page.procedureList[i].procQuestionList[j].fieldList[0].centralEdcProcFieldNo), 1);
							}
						}
					}
				}
			}

			if(type == 'PROC'){
				if (event.target.checked) {
					if(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo) < 0){
						this.checkedProcList.push(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo);
					}
					for(let i = 0; i < proc.procQuestionList.length; i++){
						let index = this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].fieldList[0].centralEdcProcFieldNo);
						if(index < 0){
							this.checkedProcQuestionList.push(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].fieldList[0].centralEdcProcFieldNo);
						}
					}
				}
				else {
					this.unCheckedLoop(type, visit, page, proc);

					if(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo) >= 0){
						this.checkedProcList.splice(this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo), 1);
					}

					for(let i = 0; i < proc.procQuestionList.length; i++){
						if(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].fieldList[0].centralEdcProcFieldNo) >= 0){
							this.checkedProcQuestionList.splice(this.checkedProcQuestionList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo + '/' + proc.procQuestionList[i].fieldList[0].centralEdcProcFieldNo), 1);
						}
					}
				}
			}

			if(type == 'PROCQUESTION'){
				if (!event.target.checked) {
					this.unCheckedLoop(type, visit, page, proc);
				}
			}
		},
		unCheckedLoop(type, visit, page, proc){
			if(type == "VISIT"){
				if(this.checkedVisitList.length == 1){
					this.isAllCheck = false;
				}
			}
			if(type == "PAGE"){
				let visitCount = 0;
				for(let i = 0; i < this.checkedPageList.length; i++){
					let split = this.checkedPageList[i].split('/');
					if(split[0] == visit.centralEdcVisitNo){
						visitCount += 1;
					}
				}
	
				if(this.checkedVisitList.indexOf(visit.centralEdcVisitNo) >= 0 && visitCount == 1){
					this.checkedVisitList.splice(this.checkedVisitList.indexOf(visit.centralEdcVisitNo), 1);
				}
			}
			if(type == "PROC"){
				let pageCount = 0;
				let visitCount = 0;
				for(let i = 0; i < this.checkedProcList.length; i++){
					let split = this.checkedProcList[i].split('/');
					if(split[0] == visit.centralEdcVisitNo && split[1] == page.centralEdcPageNo){
						pageCount += 1;
					}
					if(split[0] == visit.centralEdcVisitNo){
						visitCount += 1;
					}
				}
	
				if(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo) >= 0 && pageCount == 1){
					this.checkedPageList.splice(this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo), 1);
				}
				if(this.checkedVisitList.indexOf(visit.centralEdcVisitNo) >= 0 && visitCount == 1){
					this.checkedVisitList.splice(this.checkedVisitList.indexOf(visit.centralEdcVisitNo), 1);
				}
			}
			if(type == "PROCQUESTION"){
				let procCount = 0;
				let pageCount = 0;
				let visitCount = 0;
				for(let i = 0; i < this.checkedProcQuestionList.length; i++){
					let split = this.checkedProcQuestionList[i].split('/');
					if(split[0] == visit.centralEdcVisitNo && split[1] == page.centralEdcPageNo && split[2] == proc.procNo){
						procCount += 1;
					}
					if(split[0] == visit.centralEdcVisitNo && split[1] == page.centralEdcPageNo){
						pageCount += 1;
					}
					if(split[0] == visit.centralEdcVisitNo){
						visitCount += 1;
					}
				}
	
				let procindex = this.checkedProcList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo + '/' + proc.procNo);
				let pageindex = this.checkedPageList.indexOf(visit.centralEdcVisitNo + '/' + page.centralEdcPageNo);
				let visitindex = this.checkedVisitList.indexOf(visit.centralEdcVisitNo);
				if(procindex >= 0 && procCount == 1){
					this.checkedProcList.splice(procindex, 1);
				}
				if(pageindex >= 0 && pageCount == 1){
					this.checkedPageList.splice(pageindex, 1);
				}
				if(visitindex >= 0 && visitCount == 1){
					this.checkedVisitList.splice(visitindex, 1);
				}
			}
		}
	}
};
</script>

<style scoped>
.radio-crud {
	width: 13%;
}
.btn-cancel {
	margin-right: 10px;
}
.field-flex{
	display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 50px;
}
.title {
    font-weight: 600;
    font-size: 10.5pt;
    color: #999;
	margin-top: 10px;
}
.proc-type{
	margin-left: 40px;
    border-bottom: 1px solid #999;
}
.procQuestion-type{
	margin-left: 60px;
    border-bottom: 1px solid #999;
}
.allIcon {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0 10px 0 0px;
    border-radius: 3px;
    background-color: #dd574f;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.procIcon {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0 10px 0 0px;
    border-radius: 3px;
    background-color: #ff9dae;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.procQuestionIcon {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0 10px 0 0px;
    border-radius: 3px;
    background-color: gray;
    font-weight: 400;
    color: #fff;
    text-align: center;
}
.check-label {
    display: inline-flex;
    font-size: 10pt;
    color: inherit;
}
.label-right {
	position: absolute;
    right: 20px;
}
.check-label > input[type=checkbox] {
    display: none;
}
.check-label>.checkbox {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
	cursor: pointer;
}
.check-label > input[type=checkbox]:checked+.checkbox {
    border: 2px solid #2bade0;
}
.check-label > input[type=checkbox]:checked+.checkbox::before {
    content: "\2713";
    position: absolute;
    top: -11px;
    left: 0.5px;
    width: 16px;
    height: 16px;
    font-weight: 600;
    font-size: 13pt;
    color: #2bade0;
}

.field-flex{
	display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 50px;
	overflow: hidden;
}
</style>import { split } from "core-js/fn/symbol";
