<template>
	<div ref="projectCreate" class="dialog-container">
		<div class="dialog" style="max-height: 600px; transform: translate(0, -300px);">
			<form @submit.stop="doValidation()">
				<div class="top"> 
					<p class="txt-crud-title project-title">
						Project Create
					</p>
					
					<!-- <p class="txt-validation" v-if="errors.has('projectStateCd')">
						* {{ errors.first("projectStateCd") }}
					</p> -->

				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="83%" />
						</colgroup>
						<tbody>
							<tr>
								<th>Project Type</th>
								<td>
									<label
										v-for="(option, index) in projectTypeList"
										:key="index"
										class="radio-crud project-crud"
										:class="option == 'Attendance' ? 'radio-crud-disabled' : ''"
									>
										<input
											type="radio"
											name="projectType"
											v-validate="'required'"
											data-vv-as="Project Type"
											:value="index+1"
											v-model="items.projectType"
											:disabled="option == 'Attendance'"
										/>
										<div class="radio" />
										{{option}}
									</label>
								</td>
							</tr>
							<tr>
								<th>Protocol</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="protocolName"
										v-model="items.protocolName"
										v-validate="'required'"
										data-vv-as="Protocol"
									/>
									<!-- <input
										type="text"
										class="input-crud"
										name="protocolName"
										v-model="items.protocolName"
										v-validate="'required'"
										data-vv-as="Protocol"
										@keyup="doDuplicateCheck()"
									/> -->
									<p class="txt-validation" v-if="errors.has('protocolName')">
										* {{ errors.first("protocolName") }}
									</p>
									<!-- <p class="txt-validation" v-if="isDuplicate">
										* Protocol is duplicated.
									</p> -->
								</td>
							</tr>
							<tr>
								<th>Study Title</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="projectStudyTitle"
										v-model="items.projectStudyTitle"
										v-validate="'required'"
										data-vv-as="Study Title"
									/>
									<p class="txt-validation" v-if="errors.has('projectStudyTitle')">
										* {{ errors.first("projectStudyTitle") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>Project Manager</th>
								<td>
									<input
										type="text"
										class="input-crud half"
										:class="(errors.has('projectManager') ? 'input-crud-error' : '')"
										name="projectManager"
										v-model="items.projectManager"
										v-validate="'required'"
										data-vv-as="Project Manager"
										:readonly="!isDInputPM"
									/>
									<select
										class="select-crud"
										:class="(errors.has('projectManager') ? 'input-crud-error' : '')"
										@input="selectValue($event.target.value, 'projectManager')"
										:disabled="isDInputPM"
									>
										<option v-if="isDInputPM || !isPMData" value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in userList" :key="index" :value="item.userName">
											{{ item.userName }}
										</option>
									</select>
									<label class="checkbox-crud">
										<input type="checkbox" v-model="isDInputPM" @input="selectValue('', 'projectManager',isDInputPM)" />
										<span class="checkbox"></span>
										Direct Input
									</label>
								</td>
							</tr>
							<tr>
								<th>Lead Manager</th>
								<td>
									<input
										type="text"
										class="input-crud half"
										name="leadResearcher"
										v-model="items.leadResearcher"
										data-vv-as="Lead Researcher"
										:readonly="!isDInputLM"
									/>
									<select
										class="select-crud"
										@input="selectValue($event.target.value, 'leadResearcher')"
										:disabled="isDInputLM"
									>
										<option v-if="isDInputLM || !isLMData" value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in userList" :key="index" :value="item.userName">
											{{ item.userName }}
										</option>
									</select>
									<label class="checkbox-crud">
										<input type="checkbox" v-model="isDInputLM" @input="selectValue('', 'leadResearcher', isDInputLM)" />
										<span class="checkbox"></span>
										Direct Input
									</label>
								</td>
							</tr>
							<tr>
								<th>Sponsor</th>
								<td>
									<input
										type="text"
										class="input-crud half"
										:class="(errors.has('sponsor') ? 'input-crud-error' : '')"
										name="sponsor"
										v-model="items.sponsor"
										v-validate="'required'"
										data-vv-as="Sponsor"
										:readonly="!isDInputSP"
									/>
									<select
										class="select-crud"
										:class="(errors.has('sponsor') ? 'input-crud-error' : '')"
										@input="selectValue($event.target.value, 'sponsor')"
										:disabled="isDInputSP"
									>
										<option v-if="isDInputSP || !isSpData" value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in userList" :key="index" :value="item.userName">
											{{ item.userName }}
										</option>
									</select>
									<label class="checkbox-crud">
										<input type="checkbox" v-model="isDInputSP" @input="selectValue('', 'sponsor', isDInputSP)"  />
										<span class="checkbox"></span>
										Direct Input
									</label>
								</td>
							</tr>
							<tr>
								<th>
									Project State
								</th>
								<td>
									<label
										v-for="(option, index) in projectStateList"
										:key="index"
										class="radio-crud"
										:class="option.comCode != '1' ? 'radio-crud-disabled' : ''"
									>
										<input
											type="radio"
											name="projectStateCd"
											:value="option.comCode"
											v-model="items.projectStateCd"
											v-validate="'required'"
											data-vv-as="Project State"
											:disabled="option.comCode != '1'"
										/><div class="radio" />
										{{ option.comCodeName }}
									</label>
									<p class="txt-validation" v-if="errors.has('projectStateCd')">
										* {{ errors.first("projectStateCd") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Entry Unit
								</th>
								<td>
									<label
										v-for="(option, index) in projectEntryList"
										:key="index"
										class="radio-crud"
										:class="option.comCode != '1' ? 'radio-crud-disabled' : ''"
									>
										<input
											type="radio"
											name="projectEntryUnitCd"
											:value="option.comCode"
											v-model="items.projectEntryUnitCd"
											v-validate="'required'"
											data-vv-as="Entry Unit"
											@change="checkedEntryUnitCd(option.comCode)"
											:disabled="option.comCode != '1'"
										/><div class="radio" />
										{{ option.comCodeName }}
									</label>
									<p class="txt-validation" v-if="errors.has('projectEntryUnitCd')">
										* {{ errors.first("projectEntryUnitCd") }}
									</p>
								</td>
							</tr>
							<tr v-if="checkedEntryUnit == '2'">
								<th>
									Head Count
								</th>
								<td>
									<label
										v-for="(option, index) in projectheadCountList"
										:key="index"
										class="radio-crud"
									>
										<input
											type="radio"
											name="projectEntryHeadCount"
											:value="option"
											v-model="items.projectEntryHeadCount"
											v-validate="'required'"
											data-vv-as="Head Count"
										/><div class="radio" />
										{{ option }}
									</label>
									<p class="txt-validation" v-if="errors.has('projectEntryHeadCount')">
										* {{ errors.first("projectEntryHeadCount") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>Regist Date</th>
								<td>{{ items.firstRegistDate }}</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>{{ userInfo.userName }} ({{ userInfo.userId }})</td>
							</tr>
							<tr>
								<th>Note</th>
								<td>
									<textarea
										class="textarea-crud"
										name="projectNote"
										v-model="items.projectNote"
										data-vv-as="Note"
									></textarea>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Save
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import moment from "moment";

export default {
	name: "ProjectCreate",
	created() {
		this.logger.debug(this, "created()");
		this.items.firstRegistDate = moment().format("YYYY-MM-DD");
		this.items.projectType = "1";
		this.items.projectStateCd = "1";
		this.items.projectEntryUnitCd = "1";
		this.items.projectEntryHeadCount = "2";
		this.selectProjectStateList();
		this.selectProjectEntryList();
		this.selectUserList();
	},
	data() {
		return {
			items: {},
			projectStateList: [],
			projectEntryList: [],
			projectTypeList: ["General Clinical trial","Attendance"],
			userList: [],
			isDuplicate: false,
			isDInputPM: false,
			isDInputLM: false,
			isDInputSP: false,
			isPMData: false,
			isLMData: false,
			isSpData: false,
			checkedEntryUnit: "",
			projectheadCountList: ["2" ,"3" ,"4"]
		};
	},
	methods: {
		selectProjectStateList() {
			this.logger.debug(this, "selectProjectStateList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "PROJECT_STATE"})
				.then((response) => {
					console.log(response);
					this.projectStateList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProjectEntryList() {
			this.logger.debug(this, "selectProjectEntryList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "PROJECT_ENTRY"})
				.then((response) => {
					console.log(response);
					this.projectEntryList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectUserList() {
			this.logger.debug(this, "selectUserList()");
			axios
				.post(this.config.contextRoot + "/user/selectUserAuthList.do", {})
				.then((response) => {
					console.log(response);
					this.userList = response.data.userList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectValue(value, target, bools) {
			
			this.$set(this.items, target, value);
			if(target == 'projectManager')
			{
				if(bools == true)
				{
					this.isPMData = false;
				}
				else
				{
					this.isPMData = true;
				}
			}
			else if(target == 'leadResearcher')
			{
				if(bools == true)
				{
					this.isLMData = false;
				}
				else
				{
					this.isLMData = true;
				}
			}
			else if(target == 'sponsor')
			{
				if(bools == true)
				{
					this.isSpData = false;
				}
				else
				{
					this.isSpData = true;
				}
			}
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.doDuplicateCheck();
					if (result && !this.isDuplicate)
					{
						this.$confirm("Do you want to register the current project?","Question","question").then(() => {
							EventBus.$emit("project-loading", true);
							this.doInsert();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			axios
				.post(this.config.contextRoot + "/project/duplicateCheck.do", this.items)
				.then((response) => {
					console.log(response);
					if(response.data.projectExistCount == 0 )
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			axios
				.post(this.config.contextRoot + "/project/insert.do", this.items)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the project registration?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.projectCreate.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.projectCreate.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isCreate");
			}.bind(this), 410);
		},
		checkedEntryUnitCd(entryUnit) {
			this.logger.debug(this, "checkedEntryUnitCd()");
			this.checkedEntryUnit = entryUnit;
		}
	}
};
</script>

<style scoped>
.half {
	width: 45%;
}
.radio-crud {
	width: 13%;
}
.project-crud {
	width: 24%;
}
.select-crud {
	width: 33%;
	margin: 0 8px;
}
.checkbox-crud {
	color: #333;
	float: right;
}
.btn-cancel {
	margin-right: 10px;
}
textarea::-webkit-scrollbar {
    border-radius: 20px;
}
textarea::-webkit-scrollbar-thumb{
    float: right;
    border: 6px solid transparent;
    border-radius: 20px;
    background: linear-gradient(to top, #9ecc5a, #2bade0);
    background-clip: padding-box;
}
.radio-crud-disabled {
	opacity: 0.6;
}
</style>